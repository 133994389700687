import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import FacilityFormData from "./FacilityFormData.js";

const AddFacility = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "facility";
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo==0?'': UserInfo[0].name;

const [getFacilityCode, setFacilityCode] = useState('');
const [isCheckboxDisable, setIsCheckboxDisable] = useState(false);
const [formData, setFormData] = useState({
FacilityCode: getFacilityCode,
FacilityName: "",
RegionId:"",
// ZoneId:"",
DistrictId:"",
FTypeId:"",
FLevelId:"",
FacilityAddress:"",
FacilityPhone:"",
FacilityEmail:"",
FacilityInCharge:"",
ServiceAreaId:"",
OwnerTypeId:"",
FacilityIdDHIS2:"",
SOBAPSCode:"",
location:"",
bDispense: false,
initmothYear:""
});
const [errorObject, setErrorObject] = useState({});
const { id } = useParams();
const { t,  } = useTranslation();
const queryClient = useQueryClient();


let params={"menukey": "facility", action: "getFacilityCode"};
  const {  data:gFacilityCode, refetch } = useQuery([params], api.getFacilityCode, 
    {onSuccess: (data) => {
      
      setFacilityCode(data);
      let fdata = { ...formData }; 
      fdata["FacilityCode"] = data; 
      setFormData(fdata); 

    }}
    );


const { mutate } = useMutation(api.saveFacility, {
onSuccess: (data) => {
  if(data.status==200) {
  // api.getAllDropdown("IntervalList").then((response) => {
  //   localStorage.setItem(
  //     "IntervalList",
  //     JSON.stringify(response.datalist.IntervalList)
  //   );
  // });

  api.getAllDropdown('FacilityList').then((response) => {
    console.log(response);
    localStorage.setItem(
      "FacilityList",
      JSON.stringify(response.datalist.FacilityList)
    );
  }) 
  
  props.openNoticeModal({
    isOpen: true,
    msg: data.message,
    msgtype: data.success,
  });
  queryClient.getQueriesData("facility"); //api page name
  //props.history.push("/facility"); //
  let route = `/${menukey}/edit/${data.FacilityId}`;
  props.history.push(route);
}else{

  props.openNoticeModal({
    isOpen: true,
    msg: data.message,
    msgtype: data.success,
  });
 
}
},
});


const handleChange = (e) => {
const { name, value } = e.target;

let data = { ...formData };
console.log("formData: ", formData);
data[name] = value;

setFormData(data);

setErrorObject({ ...errorObject, [name]: null });
};

const handleCheckOnInitialDateCancel = (monthYearData) => {
 
  setIsCheckboxDisable(false);

  setFormData(Object.assign({ ...formData,
   ['bDispense']: false,
   ['YearId']:null,
   ['MonthId']:null, 
   ['InitialDate']:null,
   ['InitComplete']:null 
  }));

}

const handleCheckOnInitialDate = (monthYearData) => {
  setIsCheckboxDisable(true);
   setFormData(Object.assign({ ...formData,
    ['bDispense']: true,
    ['YearId']:monthYearData['YearID'],
    ['MonthId']:monthYearData['MonthId'], 
    ['InitialDate']:monthYearData['InitialDate'],
    ['InitComplete']:monthYearData['InitComplete'] 
   }));

}

const handleCheck = (e) => {
  const { name, checked } = e.target;

  let bDispenseVal = checked;
 
  if (bDispenseVal == true){
    setFormData({ ...formData, [name]: checked });
       
  }else{
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text:
      t(
        DispensingLanguage[lan][menukey][
          "You want to update this facility!"
        ]
      ),
      icon: "warning",
      buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setIsCheckboxDisable(true);
        setFormData({ ...formData, [name]: checked });

      } else {
        setIsCheckboxDisable(false);
        //refetch();
      }
    });
  };
      

//setFormData({ ...formData, [name]: checked });
};

const handleReset = () => {
setFormData({
FacilityCode: "",
FacilityName: "",
RegionId:"",
// ZoneId:"",
DistrictId:"",
FTypeId:"",
FLevelId:"",
FacilityAddress:"",
FacilityPhone:"",
FacilityEmail:"",
FacilityInCharge:"",
Latitude:"",
Longitude:"",
ServiceAreaId:"",
OwnerTypeId:"",
FacilityIdDHIS2:"",
SOBAPSCode:"",
location:"",
bDispense: false
});
};

const validateForm = (formData) => {
let validateFields = ["FacilityCode", "FacilityName","RegionId","DistrictId","FTypeId","FLevelId","OwnerTypeId","ServiceAreaId"];
let errorData = {};
let isValid = true;
validateFields.map((field) => {
  if (!formData[field]) {
    errorData[field] =
      DispensingLanguage[lan][menukey]["field is Required !"];
    isValid = false;
  }
});
setErrorObject(errorData);
return isValid;
};

const handleSubmit = async (e) => {
//console.log("from submit ", formData);
if (validateForm(formData)) {
  let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
  mutate(fDtat);
}
};

return (
<>
  <FacilityFormData
    errorObject={errorObject}
    addProductForm={true}
    formData={formData}
    handleChange={handleChange}
    handleCheck={handleCheck}

    handleCheckOnInitialDate={handleCheckOnInitialDate}
    handleReset={handleReset}
    handleSubmit={handleSubmit}

    handleCheckOnInitialDateCancel={handleCheckOnInitialDateCancel}
    isCheckboxDisable={isCheckboxDisable}
    {...props}
  />
</>
);
};

export default AddFacility;
